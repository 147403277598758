// extracted by mini-css-extract-plugin
export var arrow = "Work-module--arrow--n026K";
export var content = "Work-module--content--5vPGd";
export var description = "Work-module--description--DJeMi";
export var github = "Work-module--github--0UK-l";
export var name = "Work-module--name--IW3dw";
export var project = "Work-module--project--6NSC7";
export var tag = "Work-module--tag--TR5Ir";
export var tech = "Work-module--tech--m4dZY";
export var time = "Work-module--time--1q1f+";
export var wrapper = "Work-module--wrapper--u+4Rm";